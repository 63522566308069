import algoliasearch from 'algoliasearch/lite'
import Cookie from 'js-cookie'
import defaults from '@config/defaults'

const oneHundredMiles = 100 * 1609 // 160900
const twentyFiveMiles = 25 * 1609 // 40225

const getRandomInt = max => Math.floor(Math.random() * max)

const defineNextCoachNumber = (currentCoachNumber) => {
  if (Number.isNaN(currentCoachNumber)) return 1
  return currentCoachNumber + 1
}

export const getClosestCoachByIp = async (index) => {
  const { hits } = await index.search('', {
    aroundLatLngViaIP: true,
    aroundRadius: oneHundredMiles,
    aroundPrecision: [
      { from: 0, value: twentyFiveMiles },
    ],
    getRankingInfo: true,
    attributesToRetrieve: [
      'city',
      'state',
      'customSlug',
      'facilities',
      'profilePhoto',
      'firstName',
      'lastName',
    ],
  })
  if (!hits || hits.length === 0) return null

  if (hits.length === 1) return hits[0]

  const hitsWithin25Miles = hits.filter((hit) => {
    return hit._rankingInfo.geoDistance === 0
  })

  if (hitsWithin25Miles.length === 0) return hits[0]

  const randomHitNumberWithin25Miles = getRandomInt(hitsWithin25Miles.length)

  return hitsWithin25Miles[randomHitNumberWithin25Miles]
}

export const defineLpgaCutPlaces = (lbPlayers, lbCutScore, lbCutRound, currentRound) => {
  if (!lbCutScore
    || !lbCutRound
    || !currentRound
    || lbCutRound > currentRound
    || lbPlayers?.length === 0
  ) return null

  const nextRoundPlayers = lbPlayers.filter((player) => {
    if (!player || typeof player !== 'object') return false

    let playerRelativePar = 0
    let validRoundsCount = 0

    for (let i = 0; i < lbCutRound; i += 1) {
      if (player.rounds && Array.isArray(player.rounds) && player.rounds[i]) {
        const roundPar = parseFloat(player.rounds[i].relativePar)
        if (!Number.isNaN(roundPar)) {
          playerRelativePar += roundPar
          validRoundsCount += 1
        }
      }
    }

    // Consider players with at least one valid round and meeting the cut score
    return (validRoundsCount > 0 && playerRelativePar <= lbCutScore && player.currentPosition !== 'WD')
  })

  return nextRoundPlayers.length
}

export const getAdBannerData = async () => {
  const algoliaClient = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_API_KEY)
  const groupEventIndex = process.env.ALGOLIA_GROUP_EVENTS_INDEX_NAME
  const coachIndex = process.env.ALGOLIA_COACH_INDEX_NAME

  const comparisonTimestamp = Math.floor(new Date() / 1000)

  try {
    const { results } = await algoliaClient.search([
      {
        indexName: groupEventIndex,
        params: {
          filters: `tags:"PGA Jr. League" AND registration_close_date_time_ts > ${comparisonTimestamp} AND registration_closed:false AND registration_full:false`,
          hitsPerPage: 5,
          aroundLatLngViaIP: true,
          aroundRadius: oneHundredMiles,
          aroundPrecision: [
            { from: 0, value: twentyFiveMiles },
          ],
        },
      },
      {
        indexName: groupEventIndex,
        params: {
          filters: `tags:"PGA Family Cup" AND registration_close_date_time_ts > ${comparisonTimestamp} AND registration_closed:false AND registration_full:false`,
          hitsPerPage: 5,
          aroundLatLngViaIP: true,
          aroundRadius: oneHundredMiles,
          aroundPrecision: [
            { from: 0, value: twentyFiveMiles },
          ],
        },
      },
      {
        indexName: groupEventIndex,
        params: {
          filters: `(NOT tags:"PGA Family Cup") AND (NOT tags:"PGA Jr. League") AND registration_close_date_time_ts > ${comparisonTimestamp} AND (registration_closed:false OR registration_closed:null) AND registration_full:false AND is_canceled:false`,
          hitsPerPage: 5,
          aroundLatLngViaIP: true,
          aroundRadius: oneHundredMiles,
          aroundPrecision: [
            { from: 0, value: twentyFiveMiles },
          ],
        },
      },
      {
        indexName: coachIndex,
        params: {
          aroundLatLngViaIP: true,
          aroundRadius: oneHundredMiles,
          getRankingInfo: true,
          attributesToRetrieve: [
            'city',
            'state',
            'customSlug',
            'facilities',
            'profilePhoto',
            'displayName',
            'firstName',
            'lastName',
          ],
        },
      },
    ])

    const jrlResult = results[0].hits
    const fgResult = results[1].hits
    const groupEventResult = results[2].hits
    const coachResult = results[3].hits
    const currentLocation = results[0].aroundLatLng

    const jrlHit = jrlResult[getRandomInt(jrlResult.length)]
    const fgHit = fgResult[getRandomInt(fgResult.length)]
    const groupEventHit = groupEventResult[getRandomInt(groupEventResult.length)]

    let coachHit = null
    if (coachResult.length > 0) {
      let coachNumber = Number(Cookie.get(`pga_leaderboard_coach_results_${currentLocation}`))
      if (coachNumber && coachNumber >= coachResult.length) {
        coachNumber = 0
      }
      coachHit = coachNumber ? coachResult[coachNumber] : coachResult[0]
      Cookie.set(`pga_leaderboard_coach_results_${currentLocation}`, defineNextCoachNumber(coachNumber), { expires: defaults.coachAdCookieExp })
    }

    if (groupEventHit) {
      groupEventHit.eventType = groupEventHit.tags.includes('Veteran') ? 'hope-event' : 'group-event'
    }

    if (jrlHit) {
      // Add timezone info to prevent bad date parsing
      jrlHit.start_date_time += 'T16:00:00Z'
      jrlHit.end_date_time += 'T16:00:00Z'
    }

    if (fgHit) {
      // Add timezone info to prevent bad date parsing
      fgHit.start_date_time += 'T16:00:00Z'
      fgHit.end_date_time += 'T16:00:00Z'
    }

    let programHit

    if (jrlHit && fgHit) {
      jrlHit.eventType = 'jrl-program'
      fgHit.eventType = 'fg-program'
      programHit = [jrlHit, fgHit][Math.round(Math.random())]
    } else if (jrlHit) {
      jrlHit.eventType = 'jrl-program'
      programHit = jrlHit
    } else if (fgHit) {
      fgHit.eventType = 'fg-program'
      programHit = fgHit
    } else {
      programHit = null
    }

    const potentialAdData = []
    if (programHit) potentialAdData.push(programHit)
    if (groupEventHit) potentialAdData.push(groupEventHit)

    const result = { coach: coachHit, event: null }

    if (potentialAdData.length > 0) {
      result.event = potentialAdData[getRandomInt(potentialAdData.length)]
    }
    return result
  } catch (err) {
    console.error('Algolia search error:', err)
    return null
  }
}
