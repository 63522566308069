import { useEffect, useMemo, useState } from 'react'

export default function useOnScreen(ref) {
  if (!process.browser || !window.IntersectionObserver) return false
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = useMemo(() => new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting),
  ), [])

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => observer.disconnect()
  }, [ref, observer])

  return isIntersecting
}
