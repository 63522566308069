import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    height: '150px',
    background: theme.palette.common.white,
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: theme.palette.shadow.cardBoxShadow,
    marginBottom: theme.spacing(1),
    display: 'flex',
    '& a': {
      textDecoration: 'none',
      height: '100%',
    },
    '&:hover': {
      boxShadow: '0px 4px 36px rgba(0, 10, 22, 0.28)',
    },
  },
  coachRoot: {
    height: '185px',
    width: '100%',
    margin: '8px',
    background: theme.palette.common.white,
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: theme.palette.shadow.cardBoxShadow,
    marginBottom: theme.spacing(1),
    display: 'flex',
    '& a': {
      textDecoration: 'none',
      height: '100%',
    },
    '&:hover': {
      boxShadow: '0px 4px 36px rgba(0, 10, 22, 0.28)',
    },
  },
  coachCardWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    position: 'relative',
  },
  coachImageContainer: {
    width: '100%',
    minHeight: '190px',
    marginTop: '-1px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      backgroundColor: '#00234B',
      opacity: 0.9,
    },
  },
  coachImage: {
    width: '100%',
    height: '140%',
    objectFit: 'cover',
    objectPosition: 'bottom',
  },
  coachOverlayContainer: {
    position: 'absolute',
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  coachLogoContainer: {
    width: '30%',
    height: '100%',
    display: 'flex',
    padding: '50px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '80px',
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      padding: '20px',
      marginRight: '40px',
    },
    img: {
      [theme.breakpoints.down('xs')]: {
        height: '60px',
        width: '60px',
      },
    },
  },
  coachDetailsContainer: {
    width: '70%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  coachTitle: {
    color: theme.palette.gold.main,
    fontSize: '2em',
  },
  coachDateRange: {
    color: 'white',
  },
  coachField: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '10px',
    '&>svg': {
      viewBox: '8 0 24 24',
      fill: 'white',
      height: '18px',
    },
    '& span': {
      marginLeft: '-.5em',
      color: 'white',
    },
  },
  coachPriceField: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '10px',
    minWidth: '80px',
    '&>svg': {
      viewBox: '8 0 24 24',
      fill: 'white',
      height: '18px',
    },
    '& span': {
      marginLeft: '.5em',
      color: 'white',
    },
  },
  coachCta: {
    color: 'white',
    position: 'absolute',
    right: '25px',
    border: '1px solid white',
    borderRadius: '25px',
    padding: '10px 18px',
    fontSize: '16px',
  },
  coachIcon: {
    color: 'white',
  },
  cardWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    position: 'relative',
  },
  coachLocationWrapper: ({ onCoachSearch }) => ({
    display: 'flex',
    flexFlow: onCoachSearch ? 'row wrap' : 'row nowrap',
    width: 'inherit',
    marginTop: '10px',
  }),
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  imageContainer: {
    width: '30%',
    minHeight: '190px',
    marginTop: '-1px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      backgroundColor: '#00234B',
      opacity: 0.75,
    },
  },
  headlineOverlay: {
    position: 'absolute',
    top: '40%',
    left: '40%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.gold.main,
    fontSize: 'calc(1vw + 16px)',
    zIndex: 1,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'left',
    textAlign: 'left',
    paddingLeft: '5%',
  },
  programOverlay: {
    fontSize: 'calc(1vw + 12px) !important',
  },
  logoWrapper: {
    position: 'absolute',
    left: '-2.90625rem',
    top: '50%',
    transform: 'translateY(-50%)',
    [theme.breakpoints.down('xs')]: {
      left: '-1.90625rem',
    },
  },
  roundImage: {
    display: 'flex',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    border: `2px solid ${theme.palette.divider}`,
    overflow: 'hidden',
    marginRight: theme.spacing(1.5),
    marginLeft: theme.spacing(0),
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(0),
  },
  logo: {
    height: '6.25rem',
    width: '6.25rem',
    border: `1px solid ${theme.palette.grey.warm}`,
    backgroundColor: 'white',
    display: 'flex',
    borderRadius: '50%',
    objectFit: 'contain',
    padding: '15px',
    [theme.breakpoints.down('xs')]: {
      height: '4.90625rem',
      width: '4.90625rem',
    },
  },
  cardInfo: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    paddingLeft: '7rem',
  },
  headlineWrapper: {
    top: theme.spacing(4),
    marginRight: '1.5rem',
    marginBottom: '4px',
    maxWidth: '75%',
  },
  headline: {
    color: theme.palette.secondary.main,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
  dateRange: {
    '& span': {
      ...theme.typography.formTags,
      color: theme.palette.grey.dark,
      marginTop: '1rem',
      marginBottom: '0.625rem',
    },
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  detailsWrapper: {
    width: `-webkit-calc(100% - ${'3rem'})`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingRight: theme.spacing(3),
    bottom: theme.spacing(4),
    backgroundColor: 'transparent',
  },
  locationWrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
    width: 'inherit',
  },
  field: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    whiteSpace: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '10px',
    '&>svg': {
      viewBox: '8 0 24 24',
    },
    '& span': {
      marginLeft: '-.5em',
    },
  },
  priceField: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    whiteSpace: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '10px',
    minWidth: '80px',
    '&>svg': {
      viewBox: '8 0 24 24',
    },
    '& span': {
      marginLeft: '.5em',
    },
  },
  fieldText: {
    ...theme.typography.caption,
    color: theme.palette.grey.dark,
  },
  cta: {
    color: theme.palette.blue.main,
    textAlign: 'right',
    minWidth: '105px',
    paddingBottom: '2px',
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
}))

export default useStyles
