import PropTypes from 'prop-types'
import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Link from '@components/link'
import { Image } from '@components/atoms'
import {
  IconFlag,
  IconMarker,
  IconPrice,
} from '@components/atoms/icons'
import { CtaText } from '@components/atoms/cta-arrow'
import useHover from '@hooks/useHover'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useStyles from './showcase-event-bannerstyles'
import useOnScreen from '@hooks/useOnScreen'
import { formattedEventPriceLabel } from '@components/group-event-card/group-event-card'
import { gtmEvent } from '@lib/gtm-utils'
import { DateTime } from 'luxon'

const ShowcaseEventBanner = (props) => {
  const {
    title,
    coach_facility_name: course,
    coach_facility_full_address: location,
    start_date_time: startDate,
    total_price_in_cents: totalPriceInCents,
    payment_recurrence_schedule: paymentRecurrenceSchedule,
    additional_fees: additionalFees,
    price_in_cents: priceInCents,
    eventType,
    coach_id: coachId,
    objectID,
    slug,
  } = props.data

  const onCoachSearch = props.onCoachSearch || false
  const bannerAdRef = useRef(null)
  const isCardVisible = useOnScreen(bannerAdRef)
  const [bannerHasBeenViewed, setBannerHasBeenViewed] = useState(false)

  const eventTypeChoices = {
    'group-event': {
      logoUrl: onCoachSearch ? '/images/logo-white.svg' : '/images/logo-blue.svg',
      tagline: 'Want to Play like a Pro?',
      registrationLink: `${process.env.PGA_COM_URL}/things-to-do/events/${slug}`,
      backgroundImageUrl: 'https://images.ctfassets.net/56u5qdsjym8c/4PtMahTAjmw7iYKpNGUzzQ/fe039179b4f12d263a80338b60baef9a/Finau-Bunker.png',
    },
    'jrl-program': {
      logoUrl: onCoachSearch ? '/images/jr-league-logo-white.svg' : '/images/jr-league-logo.svg',
      tagline: 'Friends, Fairways & Fun',
      registrationLink: `${process.env.JRL_MARKETING_SITE_URL}/junior-league-events/${objectID}`,
      backgroundImageUrl: 'https://images.ctfassets.net/56u5qdsjym8c/1yoePOUAugYt2Za7y7Zmca/394ca263cad1c11e1344efb13ed2e0e3/JRL_programs_bg.png',
    },
    'fg-program': {
      logoUrl: onCoachSearch ? '/images/family-golf-logo-white.svg' : '/images/family-golf-logo.svg',
      tagline: 'Fairways of Family Fun',
      registrationLink: `${process.env.FG_MARKETING_SITE_URL}/events/${objectID.split('-')[1]}`,
      backgroundImageUrl: 'https://images.ctfassets.net/56u5qdsjym8c/5SWGO8vvLshekEP1mGo826/0345e17afa5d282c0bafb50174b06bf5/FG_programs_bg.png',
    },
    'hope-event': {
      logoUrl: '/images/pga-hope-logo.svg',
      tagline: 'Drive with Honor',
      registrationLink: `${process.env.PGA_COM_URL}/things-to-do/events/${slug}`,
      backgroundImageUrl: 'https://images.ctfassets.net/56u5qdsjym8c/6kJCbIwEgv3zHxS6Tu8RZA/9bfd7fd244c3006ad414918213d5be71/Costs__2_.jpg',
    }
  }

  useEffect(() => {
    if (isCardVisible && !bannerHasBeenViewed) {
      gtmEvent({
        event: 'leaderboard-event-ad-impression',
        attributes: {
          ad_type: eventType,
          start_date: DateTime.fromISO(startDate).toFormat('yyyy-LL-dd'),
          price: priceInCents / 100,
          title: title,
        },
      })
      // Remove the event below in the future.
      gtmEvent({
        event: onCoachSearch ? 'coach_search_ad_impression' : 'leaderboard_ad_impression',
        attributes: {
          bannerAdAction: 'impression',
          eventId: objectID,
          bannerAdType: eventType,
          coachId: coachId,
        }
      })
      setBannerHasBeenViewed(true)
    }
  }, [isCardVisible])

  const handleBannerClick = () => {
    gtmEvent({
      event: 'leaderboard-event-ad-click',
      attributes: {
        ad_type: eventType,
        start_date: DateTime.fromISO(startDate).toFormat('yyyy-LL-dd'),
        price: priceInCents / 100,
        title: title,
      },
    })
    // Remove the event below in the future.
    gtmEvent({
      event: onCoachSearch ? 'coach_search_ad_impression' : 'leaderboard_ad_impression',
      attributes: {
        bannerAdAction: 'click',
        eventId: objectID,
        bannerAdType: eventType,
        coachId: coachId,
      },
    })
  }

  const [isHovered] = useHover()
  const styles = useStyles({ onCoachSearch })
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const logoUrl = eventTypeChoices[eventType].logoUrl
  const backgroundImageUrl = eventTypeChoices[eventType].backgroundImageUrl
  const tagline = eventTypeChoices[eventType].tagline
  const registrationLink = eventTypeChoices[eventType].registrationLink
  const anchorProps = {
    'data-gtm-leaderboard-event-id': objectID,
    'data-gtm-leaderboard-event-type': eventType,
    'data-gtm-leaderboard-coach-id': coachId,
  }

  const start = new Date(startDate)

  const startMonth = start.toLocaleDateString('en-us', { month: 'short' })
  const startDay = start.toLocaleDateString('en-us', { day: 'numeric' })
  const startYear = start.toLocaleDateString('en-us', { year: 'numeric' })

  const dateToDisplay = `${startDay} ${startMonth}, ${startYear}`

  if (onCoachSearch) {
    return (
      <Link externalLink={registrationLink} anchorProps={anchorProps} title={title} className={styles.coachRoot}>
        <Box className={styles.coachCardWrapper} onClick={() => handleBannerClick()} ref={bannerAdRef}>
          <div className={styles.coachImageContainer}>
            <Image className={styles.coachImage} src={'https://images.ctfassets.net/56u5qdsjym8c/2qN1gBaPSgJcOpbhGZPO2I/39dd36c2378d23d90bb15d8aebe143bb/Scoring_Boot_Camp_-_Putting.png'} alt={`View page for ${title}`} />
          </div>
          <Box className={styles.coachOverlayContainer}>
            <Box className={styles.coachLogoContainer}>
              {logoUrl && <Image src={logoUrl} alt={`${title} logo`} />}
            </Box>
            <Box className={styles.coachDetailsContainer}>
              <Typography variant="h6" className={styles.coachTitle}>{title}</Typography>
              <Box className={styles.coachDateRange}>
                {dateToDisplay}
              </Box>
              <Box className={styles.coachLocationWrapper}>
                {!isMobile && course && (
                  <div className={styles.coachField}><IconFlag fill={'white'} className={styles.coachIcon} viewBox="6 0 24 24" />
                    <Typography variant="caption" className={styles.fieldText}>{course}</Typography>
                  </div>
                )}
                {!isMobile && location && (
                  <div className={styles.coachField}><IconMarker fill={'white'} className={styles.coachIcon} viewBox="6 0 24 24" />
                    <Typography variant="caption" className={styles.fieldText}>{location}</Typography>
                  </div>
                )}
                {!isMobile && (
                  <div className={styles.coachPriceField}><IconPrice fill={'white'} className={styles.coachIcon} viewBox="6 0 24 24" />
                    <Typography variant="caption" className={styles.fieldText}>
                      {formattedEventPriceLabel({
                        priceInCents: priceInCents,
                        paymentRecurrenceSchedule: paymentRecurrenceSchedule,
                        additionalFees: additionalFees,
                        totalPriceInCents: totalPriceInCents,
                        isJRL: eventTypeChoices[eventType] === 'jrl-program',
                        isFG: eventTypeChoices[eventType] === 'fg-program',
                      })}
                    </Typography>
                  </div>
                )}
              </Box>
              {!isMobile && (
                <Box className={styles.coachCta}>
                  View Event
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Link>
    )
  }

  return (
    <Link externalLink={registrationLink} anchorProps={anchorProps} className={styles.root} title={title}>
      <Box className={styles.cardWrapper} onClick={() => handleBannerClick()} ref={bannerAdRef}>
        <div className={styles.imageContainer}>
          {backgroundImageUrl && <Image className={styles.image} src={`${backgroundImageUrl}?h=200&w=600&fit=fill&fl=progressive&q=80`} alt={`View page for ${title}`} />}
          {!isMobile && <Typography variant="h6" className={clsx(styles.headlineOverlay, eventType === "jrl-program" && styles.programOverlay)}>{tagline}</Typography>}
        </div>
        <Box className={styles.cardInfo} aria-hidden="true">
          <Box className={styles.logoWrapper}>
            {logoUrl && <Image className={styles.logo} src={logoUrl} alt={`${title} logo`} />}
          </Box>
          <Box className={styles.headlineWrapper}>
            <Typography variant="h6" className={styles.headline}>{title}</Typography>
            <Box className={styles.dateRange}>
              {dateToDisplay}
            </Box>
          </Box>
          <Box className={styles.detailsWrapper}>
            <Box className={styles.locationWrapper}>
              {!isMobile && course && (
                <div className={styles.field}><IconFlag className={styles.icon} viewBox="6 0 24 24" />
                  <Typography variant="caption" className={styles.fieldText}>{course}</Typography>
                </div>
              )}
              {!isMobile && location && (
                <div className={styles.field}><IconMarker className={styles.icon} viewBox="6 0 24 24" />
                  <Typography variant="caption" className={styles.fieldText}>{location}</Typography>
                </div>
              )}
              {!isMobile && (
                <div className={styles.priceField}><IconPrice className={styles.icon} viewBox="6 0 24 24" />
                  <Typography variant="caption" className={styles.fieldText}>
                    {formattedEventPriceLabel({
                      priceInCents: priceInCents,
                      paymentRecurrenceSchedule: paymentRecurrenceSchedule,
                      additionalFees: additionalFees,
                      totalPriceInCents: totalPriceInCents,
                      isJRL: eventTypeChoices[eventType] === 'jrl-program',
                      isFG: eventTypeChoices[eventType] === 'fg-program',
                    })}
                  </Typography>
                </div>
              )}
            </Box>
            <CtaText hovered={isHovered} variant="subtitle2" className={styles.cta}>
              View Event
            </CtaText>
          </Box>
        </Box>
      </Box>
    </Link>
  )
}

ShowcaseEventBanner.propTypes = {
  title: PropTypes.string,
  coach: PropTypes.object,
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  logo: PropTypes.shape({
    url: PropTypes.string,
  }),
  slug: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  location: PropTypes.string,
  course: PropTypes.string,
  price: PropTypes.string,
  heroImage: PropTypes.shape({
    url: PropTypes.string,
  }),
}

export default ShowcaseEventBanner
